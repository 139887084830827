import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import MainPage from './components/pages/main-page/MainPage';
import PrivacyPolicy from './components/pages/privacy-policy/PrivacyPolicy';
import ERTNext from './components/pages/ertnext/index.';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/privacy_policy" component={PrivacyPolicy} />
          <Route path="/ertnext" component={ERTNext} />
          <Route path="/" component={MainPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
