import React, { useState } from "react";

import "./Navbar.scss";

import Link from "./link/Link";
import LOGO from "../../../assets/images/logo.svg";
import MENU_ICON from "../../../assets/images/icons/menu.png";

const links = [
  {
    id: "home",
    title: "HOME",
  },
  {
    id: "about",
    title: "ABOUT",
  },
  {
    id: "membership",
    title: "MEMBERSHIP",
  },
  {
    id: "advisory_boards",
    title: "ADVISORY BOARDS",
    links: [
      {
        id: "switzerland",
        title: "SWITZERLAND",
      },
      {
        id: "germany",
        title: "GERMANY",
      },
      {
        id: "france",
        title: "FRANCE",
      },
      {
        id: "the_netherlands",
        title: "THE NETHERLANDS",
      },
      {
        id: "poland",
        title: "POLAND",
      },
    ],
  },
  {
    id: "founders",
    title: "FOUNDERS",
  },
  {
    id: "testimonials",
    title: "TESTIMONIALS",
  },
  {
    id: "ertnext",
    title: "ERT NEXT",
    url: "/ertnext",
  },
  {
    id: "contact",
    title: "CONTACT",
  },
];

export default function Navbar() {
  const [activeLink, setActiveLink] = useState(links[0].id);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isPhoneMenuOpen, setIsPhoneMenuOpen] = useState(false);

  const checkActiveLink = (link) => {
    let result = false;
    if (link.id === activeLink) {
      return true;
    }

    if (link.links) {
      for (let linkEle of link.links) {
        if (linkEle.id === activeLink) {
          result = true;
          break;
        }
      }
    }
    return result;
  };

  return (
    <div className="Navbar">
      <div className="logo-container">
        <a href="#home" className="img-link">
          <img src={LOGO} alt="Entrepreneur's Roundtable" />
        </a>
      </div>

      <div
        className={"links-container " + (isPhoneMenuOpen ? "phone-view" : "")}
      >
        {links.map((link) => (
          <Link
            key={link.id}
            link={link}
            activeMainLink={checkActiveLink(link)}
            setActiveLink={(value) => setActiveLink(value)}
            activeLink={activeLink}
            activeDropdown={activeDropdown}
            setActiveDropdown={(value) => setActiveDropdown(value)}
            setIsPhoneMenuOpen={(value) => setIsPhoneMenuOpen(value)}
            url={link.url}
          />
        ))}
      </div>

      <div
        className="links-container-menu-btn"
        onClick={() => {
          setIsPhoneMenuOpen(!isPhoneMenuOpen);
        }}
      >
        <img src={MENU_ICON} alt="menu" />
      </div>
    </div>
  );
}
