import React from "react";
import { useLocation } from "react-router-dom";

export default function Link(props) {
  const {
    link,
    activeMainLink,
    activeLink,
    setActiveLink,
    activeDropdown,
    setActiveDropdown,
    setIsPhoneMenuOpen,
  } = props;
  const location = useLocation(); // Get the current location
  const isERTNextPage = location.pathname === "/ertnext";
  const renderLink = () => {
    const handleClick = (e) => {
      if (link.id === "ertnext") {
        e.preventDefault();
        window.open(link.url, "_blank"); // Open ERT NEXT in a new tab
      } else {
        // Check if we are on the ERT NEXT page
        const currentPath = window.location.pathname;
        if (currentPath.includes("ertnext")) {
          // If on ERT NEXT, navigate to home and scroll to the section
          e.preventDefault();
          window.location.href = "/#" + link.id; // Redirect to home and scroll to the section
        } else {
          setActiveLink(link.id);
          setIsPhoneMenuOpen(false);
        }
      }
    };

    return (
      // <a
      //   key={link.id}
      //   href={link.url ? link.url : `/${link.id === 'home' ? '' : `#${link.id}`}`}
      //   id={"link-" + link.id}
      //   className={"link " + (activeLink === link.id ? ' active' : '')}
      //   onClick={handleClick}
      // >
      //   {link.title}
      // </a>
      <a
        key={link.id}
        href={
          link.url ? link.url : `/${link.id === "home" ? "" : `#${link.id}`}`
        }
        id={"link-" + link.id}
        className={
          "link " +
          // Add 'active' class if it's 'ertnext' page or the link is the active one
          ((isERTNextPage && link.id === "ertnext") ||
          (!isERTNextPage && activeLink === link.id)
            ? " active"
            : "")
        }
        onClick={handleClick}
      >
        {link.id === "ertnext" ? (
          <p>
            ERT <span className="next"> NEXT</span>
          </p>
        ) : (
          link.title
        )}
      </a>
    );
  };

  const renderDropdownTrigger = () => {
    return (
      <span
        key={link.id}
        id={"link-" + link.id}
        className={"link " + (activeMainLink ? " active" : "")}
        onClick={(e) => {
          if (activeDropdown === link.id) {
            setActiveDropdown(null);
          } else {
            setActiveDropdown(link.id);
          }
        }}
      >
        {link.title}
        <div
          className={"dropdown " + (activeDropdown === link.id ? "open" : "")}
        >
          {renderDropdown(link.links)}
        </div>
      </span>
    );
  };

  const renderDropdown = (links) => {
    return links.map((link) => (
      <a
        key={link.id}
        href={"#" + link.id}
        id={"link-" + link.id}
        className={"dropdown-link " + (activeLink === link.id ? " active" : "")}
        onClick={(e) => {
          setActiveLink(link.id);
          setIsPhoneMenuOpen(false);
        }}
      >
        {link.title}
        {link.links ? (
          <div
            className={"dropdown " + (activeDropdown === link.id ? "open" : "")}
          >
            {renderDropdown(link.links)}
          </div>
        ) : null}
      </a>
    ));
  };

  return (
    <div className="Link">
      {link.links ? renderDropdownTrigger() : renderLink()}
    </div>
  );
}
