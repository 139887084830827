// src/components/pages/ertnext/jsx/ERTNext.jsx
import React from "react";
import "./ERTNext.scss"; // Import the SCSS for styling
import Navbar from "../../shared/navbar/Navbar";
export default function ERTNext() {
  return (
    <>
      <Navbar />
      <div className="ERTNext page-section-auto ert-blackish-background">
        <div className="ert-container">
          <img
            src="/assets/images/coming.png"
            alt="ertnext"
            className="ertnext-text-img-2"
          />
          <img
            src="/assets/images/ert-back.png"
            alt="ertnext"
            className="ertnext-text-img-1"
          />
          <div>
            <p className="ertnext-text mt-5">Kickoff Event</p>
            <p className="ertnext-text">6 March 2025</p>
          </div>
        </div>
      </div>
    </>
  );
}
